.btn .btn_mes{
    margin-left: 15px;
    width: 50px;
    background-color: tomato;

}

.btn_mes{
    margin-left: 5px;
    width: 90px;
    font-size: 14px;



}

.div_centro{
    display:flex;
    justify-content:center;
    align-items: center;
    flex-wrap: wrap;

}





