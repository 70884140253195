.btn_fechar{
    margin: 5px 20px 10px 20px;
    width: 80px;
}

.input_data{
    width: 250px;
}

.div_input{
    max-width: 550px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}