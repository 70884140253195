.geral{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;

}
.item{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;

}

.form{
    border: 0.5px solid grey;
    width: 300px;
    border-radius: 10px;
}