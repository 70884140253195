.btn-acao{
    margin: 20px 20px 0px 20px;
    min-width: 100px;
}

.div_lista{
 
 display: flex;
 flex-direction:row;
 justify-content:space-between;
 align-items:center;
 flex-wrap: wrap;
 margin-top: 30px;
 width: 400px;
}

.div_geral_listarfiscais{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    padding: 40px;
    margin-top: 50px;
    max-width: 350px;
    
}
.div_conteudo_listarfiscais{
    background-color:#FFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    border-radius: 10px;
    background-color: #f3f3f3;
    -webkit-box-shadow: 1px 2px 15px 5px rgba(0,0,0,0.37); 
    box-shadow: 1px 2px 15px 5px rgba(0,0,0,0.37);
    
    
}
.lista{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
}

