.titulo{
    margin-top: 80px;
}

.btn{
    margin-left: 15px;
    font-size: 14px;
}
.pesquisar{
        padding-left: 50px;
        font-size: 16px;
        border-width: 1px;
        border-color: #CCCCCC;
        background-color: #FFFFFF;
        color: #000000;
        border-style: solid;
        border-radius: 8px;
        box-shadow: 0px 0px 0px rgba(66,66,66,.75);
        text-shadow: 0px 0px 0px rgba(66,66,66,.75);
        margin-right: 15px;
        max-width: 300px;
       
   }
.pesquisar:focus {
        outline:none;
   }

.div_linha{
display: flex;
justify-content: flex-end;
padding-bottom: 10px;
}

.btn-primary{
    border-radius: 10px;
}

.final_home{
    width: 100%;
    height: 30px;
    background-color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin-top: 30px;
}

.div_botao{
    display: flex;
    justify-content: flex-end;
  }
  
  .botao_modal{
    background-color: #61dafb;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .div_modal{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .Modal {
    position: relative;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right:  -50%;
    transform: translate(-50%, -50%);
    background-color: rgb(225, 236, 252);
    opacity: 0.5;
    width: 300px;
    height: 300px;
  
  }

  .main{
    height: 100vh;
    display: flex;
    width: 1280px;
    margin: 0 auto;
  }

#btn_pdf{
  width: 130px;
  margin-left: 0;
}

.row{
  margin-bottom: 10px;
}

.div_periodos{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.btn_pesquisar{
  margin-left: 20px;
  display: flex;
  align-items: center;
}
