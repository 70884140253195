.console{
    background-color: #333;
    color:rgb(76, 245, 9);
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    height: 100vh;

}
.cabeca{
    display: flex;
    justify-content: center;

}
.conteudo{
    display: flex;
    justify-content: center;
    margin-top: 50px;
    border: 2px solid #fff;
}

.modal01{
    position: relative;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right:  -50%;
    transform: translate(-50%, -50%);
    height: 500px;
    width: 500px;
    background-color:blueviolet;
    border-radius: 10px;
}
.modal02{
    position: relative;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right:  -50%;
    transform: translate(-50%, -50%);
    height: 500px;
    width: 500px;
    background-color:rgb(24, 226, 84);
    border-radius: 10px;
}
.centro{
    display: flex;
    justify-content: center;
    color: cornsilk;
}
.btn_modal{
    margin-left: 15px;
    outline: none;
    border-radius: 8px;
    border: none;
    background-color: cornsilk;
}
.btn_modal:hover{
    background-color: cyan;
}

.grow{
    flex-grow: 1;
}

.navbar_btn{
    margin-right: 20px !important;
}

.navbar_bell{
    margin: 20px !important;
}
.navbar_avatar{
  margin-left: 20px;
}

.useSection{
    display: flex;
    align-items: center;
}
.div_tabela{
    display: flex;
    justify-content: center;
    height: 400px;
   width: 100%;
}

.tabela{
    background-color: #fff;
}
.btn_delete{
    background-color: transparent;
    border: none;
    font-size: 14px;
}

.icone_acao{
    color:rgb(19, 130, 150);
}

.divmodal_editarplanejador{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 600px;
}

.btn_cancelar{
    width: 150px !important;
    margin: 0;
}

.cabeca_table{
    font-size: 20px;
}