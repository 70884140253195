.div_geral_novaconta {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    padding: 40px;
    margin-top: 50px;
    max-width: 350px;

}

.div_conteudo_novaconta {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 570px;
    width: 350px;
    margin-bottom: 20px;
    background-color: #f3f3f3;
    border-radius: 10px;
    -webkit-box-shadow: 1px 2px 15px 5px rgba(0, 0, 0, 0.37);
    box-shadow: 1px 2px 15px 5px rgba(0, 0, 0, 0.37);

}


.div_btn_novaconta {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 10px;

}

.btn_novaconta {
    width: 80%;
    margin: 0 !important;
}

.form-floating {
    width: 90%;
}