.div_editarplanejador{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 550px;
    flex-direction: column;
    margin-top: 80px;
    border: 0.5px solid grey;
    padding-top: 25px;
    padding-bottom: 25px;
    background-color: #fff;
    border-radius: 10px;
    -webkit-box-shadow: 1px 2px 15px 5px rgba(0,0,0,0.37); 
    box-shadow: 1px 2px 15px 5px rgba(0,0,0,0.37);
}

.divbtn_fechar{
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.btn_salvar{
    width: 150px !important;
    margin:0;
}

.form_editarplanejador{
    width: 300px;
}

