.navbar {
    background-color: #333;
}

.navbar .logout {
    cursor: pointer;
}

.btn_senha {
    display: block;
    border: none;
    background-color: #333;
    font-size: 14px;
    color: rgba(255, 255, 255, .55);
    margin-left: 15px;
    margin-top: 7px;
}

.navbar_logo {
    max-height: 35px;
}

.grow {
    flex-grow: 1;
}

.navbar_btn {
    margin-right: 20px !important;
    color: #fff !important;
}

.navbar_bell {
    margin: 20px !important;
}

.navbar_avatar {
    margin-left: 20px;
}

.useSection {
    display: flex;
    align-items: center;
}

.fas {
    margin-right: 5px;
}

.navMenu {
    color: #333;
    /* margin-left: 9px; */
}

.navMenu:hover {
    color: rgb(15, 176, 216);
}

.navMenu_btn {
    background-color: transparent;
    border: none;
    margin-left: 0;
}

.navMenu_btn:hover {
    color: rgb(15, 176, 216);
}

.div_usuario {
    margin-right: 30px;
    opacity: 0.8;
}